import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useStoreState } from './Store.js';

const Credentials = ({ redirect, requirements }) => {
  const { secret } = useStoreState();

  let history = useHistory();
  let location = useLocation();
  const [errorStatus, setErrorStatus] = useState(false);

  useEffect(() => {if (redirect) history.push('/info')}, [redirect, history]);

  const { register, handleSubmit } = useForm();
  const onSubmit = data => {
    console.log(data);

    fetch(`/credentials?auth_secret=${secret}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    })
    .then((resp) => resp.ok
      ? history.push('/info')
      : setErrorStatus(true))
    .catch((err) => {
      setErrorStatus(true);
      console.log(err);
    });
  };

  return <>
    <div className="credentials">
      <form onSubmit={handleSubmit(onSubmit)}>
        {
          requirements.map(req => <label key={req.key}>
            <div className="label">{req.label}</div>
            <div className="field"><input type="text" name={req.key} ref={register} /></div>
          </label>
          )
        }
        <label>
          <div className="label">Front API key (optional)</div>
          <div className="field"><input type="text" name="FRONT_TOKEN" ref={register} /></div>
        </label>
        <input type="submit" value="Save" />
        {location.pathname !== '/' ? (
          <span className="close-button">
            <Link to="/info">Close</Link>
          </span>
        ) : ''}
        {errorStatus ? <div className="error-message">Invalid credentials.</div> : ''}
      </form>
    </div>
  </>;
};

export default Credentials;
import React from 'react';
import { DateTime } from 'luxon';

const Date = ({value}) => { 
  const parsedDate = DateTime.fromISO(value);

  if (parsedDate.invalid)
    return <em>Not found.</em>;
  
  return parsedDate.toFormat('D');
};

export default Date;